<template>
  <div class="common-layout">
    <el-container style="min-height: 100vh;">
       <el-header style="min-width: 900px;">
         <div class="container_wrap">
           <div class="logo" @click="goPath('/home')" style="cursor:pointer;">
                <img alt="未来网络学堂" :src="require('/src/assets/img/logo/fnedu.png')">
            </div>
            <ul :class="{'menu':true, 'menu_hidden':isShowSearch}" id="menuList">
              <li v-for="(item, index) in visibleNavData" :key="index" :class="{'menu_item_li': true, 'active': item.path === activeIndex}" @click="goNav(item)">
                <a v-if="item.link_url && item.link_url !== ''" class="menu_item" :href="item.link_url" :target="item.open_mode === 2 ? '_blank' : ''">{{ item.name }}</a>
                <router-link v-else :to="item.path" class="menu_item" :target="item.open_mode === 2 ? '_blank' : ''">{{ item.name }}</router-link>
              </li>
              <li class="menu_more" @mouseenter="showMoreList = true" @mouseleave="showMoreList = false" v-if="showMore">
                <a href="javascript:;">
                  <i class="el-icon-more"></i>
                </a>
                <ul class="menu_more_list" v-if="showMoreList">
                  <li v-for="(item, index) in hideNavData" :key="index" :class="{'active': item.path === activeIndex}" @click="goNav(item)">
                    <a v-if="item.link_url && item.link_url !== ''" class="menu_item" :href="item.link_url" :target="item.open_mode === 2 ? '_blank' : ''">{{ item.name }}</a>
                    <router-link v-else :to="item.path" class="menu_item" :target="item.open_mode === 2 ? '_blank' : ''">{{ item.name }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
<!--           <el-link :underline="false" href="/certificatequery" :style="{'color': (activeIndex === '/certificatequery' ? '#00adef' : '#666'), 'margin-right':'15px', 'font-size':'15px'}">证书查询</el-link>-->
<!--           <el-link :underline="false" href="/aboutus" :style="{'color': (activeIndex === '/aboutus' ? '#00adef' : '#666'), 'margin-right':'15px', 'font-size':'15px'}">关于我们</el-link>-->
           <div :class="{'search_wrap':true, 'active': isShowSearch}">
              <el-input ref="searchCur" v-model="searchInput" placeholder="请输入您想要搜索的内容" prefix-icon="el-icon-search"
                        v-if="isShowSearch" @blur="isShowSearch=false" @keypress="searchContentHandler"></el-input>
              <span class="el-icon-search" @click="showSearchHandler" v-if="!isShowSearch"></span>
              <span class="el-icon-close" @click="isShowSearch = false" v-else></span>
            </div>
            <span class="shopping_cart" v-if="!user.is_authenticated">
              <span class="el-link--inner" style="cursor:pointer;" @click="loginModal.visible = true">
                <i class="el-icon-shopping-cart-2"></i>
              </span>
            </span>
            <el-button v-if="!user.is_authenticated" type="text" @click="loginModal.visible = true" class="log_and_reg">注册/登录</el-button>
            <el-badge :value="cartItemCount" type="danger" class="shopping_cart" v-if="user.is_authenticated">
              <el-link :underline="false" href="/cart"><i class="el-icon-shopping-cart-2"></i></el-link>
            </el-badge>
            <el-dropdown :hide-timeout="1000" v-if="user.is_authenticated" @command="userCommandHandler">
              <span class="el-dropdown-link">
                  <img alt="head" :src="imgDataUrl">
                  <span class="name" :title="user.user_name">{{ user.user_name }}</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item command="center">个人中心</el-dropdown-item>
                  <el-dropdown-item command="repwd">修改密码</el-dropdown-item>
                  <el-dropdown-item command="logout">退出</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
         </div>
       </el-header>
       <el-container class="container">
          <!-- 主内容 -->
            <el-main>
               <router-view v-slot="{ Component }"
                            v-model:loginVisible="loginModal.visible"
                            v-model:cartItemCount="cartItemCount">
                   <transition name="slide-fade" mode="out-in" appear>
                       <component :is="Component"></component>
                   </transition>
               </router-view>
            </el-main>
<!--          </div>-->
       </el-container>
        <el-footer height="45px">
          <div id="footer">
            <div class="bottom">
              Copyright ©2014-2022 南京优速网络科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/">苏ICP备13052757号-5</a>
<!--              <router-link to="/aboutus" style="margin-left:20px;">关于我们</router-link>-->
            </div>
          </div>
        </el-footer>
    </el-container>

    <!-- 登录/注册 -->
    <el-dialog v-model="loginModal.visible" width="480px" custom-class="login_modal" @closed="closeLoginModal">
        <div v-if="loginModal.isLogin">
          <div class="content_wrap">
            <h3>用户登录</h3>
            <el-alert v-if="loginModal.login_error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ loginModal.login_error }}</el-alert>
            <el-form :model="loginModal.loginForm" :rules="rules" ref="loginForm">
              <el-form-item prop="tel">
                <el-input placeholder="请输入手机号/用户名/邮箱" v-model="loginModal.loginForm.tel"></el-input>
              </el-form-item>
              <el-form-item prop="pwd">
<!--                <el-input placeholder="请输入密码" v-model="loginModal.loginForm.pwd" show-password autocomplete="new-password"></el-input>-->
                <PwdInput v-model:pwd="loginModal.loginForm.pwd" />
              </el-form-item>
              <el-form-item prop="verify">
                <PuzzleVerify :width="380" @status="getLoginStatus" @result="getLoginResult" v-if="loginModal.visible"></PuzzleVerify>
              </el-form-item>
              <el-form-item class="btns">
                <el-button type="primary" style="width:100%;" @click="submitLoginForm()">立即登录</el-button>
                <el-checkbox v-model="loginModal.loginForm.autoLogin">下次自动登录</el-checkbox>
                <el-button type="text" class="pull-right" @click="showForgetPwd">忘记密码？</el-button>
              </el-form-item>
            </el-form>
            <div class="sep"><span>第三方账户登录</span></div>
            <div class="third_login">
              <el-button type="text" @click="startThirdPartyLogin('qq')" class="iconfont icon-111_huaban1" style="color:#18acfc;"></el-button>
              <el-button v-if="false" type="text" @click="startThirdPartyLogin('weixin')" class="iconfont icon-111-02" style="color:#00bb29;"></el-button>
              <el-button type="text" @click="startThirdPartyLogin('weibo')" class="iconfont icon-111-03" style="color:#d72b2b;"></el-button>
              <el-button type="text" @click="startThirdPartyLogin('baidu')" class="iconfont icon-111-04" style="color:#306cff;"></el-button>
              <el-button type="text" @click="startThirdPartyLogin('github')" class="iconfont icon-111-05" style="color:#1f76b6;"></el-button>
            </div>
          </div>
          <div class="to_register">
            还没账户？<el-button type="text" @click="openRegister()">去注册 >></el-button>
          </div>
        </div>
        <div v-else>
          <div class="content_wrap">
            <h3>用户注册</h3>
            <el-alert v-if="loginModal.register_error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ loginModal.register_error }}</el-alert>
            <el-form :model="loginModal.registerForm" :rules="rules" ref="registerForm">
              <el-form-item prop="register_tel">
                <el-input placeholder="请输入手机号" class="input_tel" v-model="loginModal.registerForm.register_tel">
                  <template #prefix>
                    CN + 86
                  </template>
                </el-input>
              </el-form-item>
              <el-form-item prop="register_pwd">
<!--                <el-input placeholder="请输入密码" v-model="loginModal.registerForm.register_pwd" show-password autocomplete="new-password"></el-input>-->
                <PwdInput v-model:pwd="loginModal.registerForm.register_pwd" />
              </el-form-item>
              <el-form-item prop="yzm" v-if="!loginModal.isVerify">
                <div class="get_yzm">
                  <el-input :placeholder="loginModal.registerForm.yzm_placeholder"
                            v-model="loginModal.registerForm.yzm"></el-input>
                  <el-button type="text" @click="getSms()" v-if="!loginModal.isGetYzm">获取验证码</el-button>
                  <span v-else>{{ loginModal.countdown }}</span>
                </div>
              </el-form-item>
              <el-form-item prop="verify" v-else>
                <PuzzleVerify :width="380" @status="getRegisterStatus" @result="getRegisterResult"></PuzzleVerify>
              </el-form-item>
              <el-form-item class="btns" prop="agree">
                <el-button type="primary" style="width:100%;" @click="submitRegisterForm()">立即注册</el-button>
                <el-checkbox v-model="loginModal.registerForm.agree">同意<router-link to="/agreement" target="_blank">《未来网络学堂用户注册使用协议》</router-link> </el-checkbox>
              </el-form-item>
            </el-form>
          </div>
          <div class="to_register">
            已有账户，<el-button type="text" @click="openLogin()">立即登录 >></el-button>
          </div>
        </div>
    </el-dialog>

    <!-- 忘记密码 -->
    <el-dialog
        title="忘记密码"
        v-model="forgetPwdModal.visible"
        @closed="closeForgetPwdModal"
        width="500px" custom-class="forget_pwd_modal">
        <div v-if="forgetPwdModal.visible">
          <el-alert v-if="forgetPwdModal.error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ forgetPwdModal.error }}</el-alert>
          <el-form :model="forgetPwdModal.form" :rules="rules" ref="forgetPwdForm">
            <el-form-item prop="forget_tel">
              <el-input placeholder="请输入手机号" class="input_tel" v-model="forgetPwdModal.form.forget_tel">
                <template #prefix>
                  CN + 86
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="pwd">
<!--              <el-input placeholder="请输入新密码" v-model="forgetPwdModal.form.pwd" show-password autocomplete="new-password"></el-input>-->
              <PwdInput v-model:pwd="forgetPwdModal.form.pwd" placeholder="请输入新密码" />
            </el-form-item>
            <el-form-item prop="yzm" v-if="!forgetPwdModal.isVerify">
              <div class="get_yzm">
                <el-input :placeholder="forgetPwdModal.form.yzm_placeholder"
                          v-model="forgetPwdModal.form.yzm"></el-input>
                <el-button type="text" @click="getForgetSms()" v-if="!forgetPwdModal.isGetYzm">获取验证码</el-button>
                <span v-else>{{ forgetPwdModal.countdown }}</span>
              </div>
            </el-form-item>
            <el-form-item prop="verify" v-else>
              <PuzzleVerify :width="460" :height="200" @status="getForgetPwdStatus" @result="getForgetPwdResult"></PuzzleVerify>
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer">
              <el-button @click="closeForgetPwdModal()">取 消</el-button>
              <el-button type="primary" @click="submitForgetForm()">确 定</el-button>
            </span>
        </template>
    </el-dialog>
    <!-- 补充手机号 -->
    <el-dialog
        title="补充手机号码"
        v-model="addCellPhoneModal.visible" @close="closeAddPhoneModal"
        width="500px" custom-class="forget_pwd_modal">
        <div>
          <el-alert v-if="addCellPhoneModal.error" type="error" show-icon :closable="false" style="margin-bottom:20px;">{{ addCellPhoneModal.error }}</el-alert>
          <el-form :model="addCellPhoneModal.form" :rules="rules" ref="addCellPhoneForm">
            <el-form-item prop="addtel">
              <el-input placeholder="请输入手机号" class="input_tel" v-model="addCellPhoneModal.form.addtel">
                <template #prefix>
                  CN + 86
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="yzm" v-if="!addCellPhoneModal.isVerify">
              <div class="get_yzm">
                <el-input :placeholder="addCellPhoneModal.form.yzm_placeholder"
                          v-model="addCellPhoneModal.form.yzm"></el-input>
                <el-button type="text" @click="getAddSms()" v-if="!addCellPhoneModal.isGetYzm">获取验证码</el-button>
                <span v-else>{{ addCellPhoneModal.countdown }}</span>
              </div>
            </el-form-item>
            <el-form-item prop="verify" v-else>
              <PuzzleVerify :width="460" :height="200" @status="getAddPhoneStatus" @result="getAddPhoneResult"></PuzzleVerify>
            </el-form-item>
          </el-form>
        </div>
        <template #footer>
            <span class="dialog-footer">
              <el-button @click="addCellPhoneModal.visible = false">取 消</el-button>
              <el-button type="primary" @click="submitAddPhoneForm()">确 定</el-button>
            </span>
        </template>
    </el-dialog>
    <!-- 重置密码 -->
    <ResetPwdModal v-model:visible="resetModalVisible"></ResetPwdModal>
    <!-- 右侧悬浮列按钮 -->
    <SideBtns></SideBtns>
  </div>
</template>

<script>
import PuzzleVerify from 'components/common/PuzzleVerify'
import ResetPwdModal from 'components/ResetPwdModal.vue'
import SideBtns from 'components/SideBtns.vue'
import PwdInput from 'components/common/PwdInput'
import { checkPhone, checkPassword, routePathHandler } from 'utils/common'
import { showConfirmMessageBox, showMessage } from 'api/base/message'
import { checkPhoneExist, SendSms, RegisterUser } from 'api/user/auth/register'
import { GetUserState, LoginUser, LogOutUser, ThirdPartyLogin, AddCellPhone, ForgetPWD, updateUserLoginState } from 'api/user/auth/login'
import { countCartItems } from 'api/settlement/cart/user'
import { clickNav } from "api/nav/list"
import { replaceAll } from "api/base/common"

export default {
    components: {
      PuzzleVerify,
      ResetPwdModal,
      SideBtns,
      PwdInput
    },
    props: {
      loginVisible: {
        type: Boolean,
        default: false
      }
    },
    provide() {
        return {
            is_authenticated: this.user.is_authenticated,
            user_id: this.user.user_id,
            user_name: this.user.user_name
        }
    },
    data() {
        let checkPhoneExistRule = (rule, value, callback) => {
          checkPhoneExist(value).then((res) => {
            if (!res.result) {
              callback()
            } else {
              return callback(new Error('手机号码已经被注册'))
            }
          }).catch(err => {
            return callback(new Error('服务异常，请稍后再试'))
          });
        }

        let checkPhoneNotExistRule = (rule, value, callback) => {
          checkPhoneExist(value).then((res) => {
            if (!res.result) {
              return callback(new Error('该手机号码不存在'))
            } else {
              callback()
            }
          }).catch(err => {
            return callback(new Error('服务异常，请稍后再试'))
          });
        }

        return {
          showMore: false,
          showMoreList: false,
          activeIndex: '/home',
          navData: [],
          visibleNavData: [],
          hideNavData: [],
          imgDataUrl: require('/src/assets/img/logo.png'),
          searchInput: '',
          showSearchInput: '',
          isShowSearch: false,
          loginModal: {
            visible:false,
            login_error: '',
            isLogin: true,
            isGetYzm: false,
            isVerify: false,
            countdown: '等待 60 s',
            loginForm: {
              tel: '',
              pwd: '',
              verify: '',
              autoLogin: true,
            },
            register_error: '',
            registerForm: {
              register_tel: '',
              register_pwd: '',
              yzm: '',
              yzm_placeholder: '请输入短信验证码',
              agree: [],
            },
          },
          forgetPwdModal: {
            error: '',
            visible: false,
            isGetYzm: false,
            isVerify: false,
            countdown: '等待 60 s',
            countdowntime: 60,
            form: {
              forget_tel: '',
              yzm: '',
              yzm_placeholder: '请输入短信验证码',
              pwd: '',
              verify: '',
            }
          },
          addCellPhoneModal: {
            error: '',
            visible: false,
            isGetYzm: false,
            isVerify: false,
            countdown: '等待 60 s',
            form: {
              addtel: '',
              yzm: '',
              yzm_placeholder: '请输入短信验证码',
              verify: '',
              token: '',
              target: '',
              user_id: ''
            }
          },
          rules: {
            register_tel: [
              { required: true, validator: checkPhone, trigger: 'blur' },
              { required: true, validator: checkPhoneExistRule, trigger: 'blur' }
            ],
            register_pwd: [
              { required: true, message: '请输入密码', trigger: 'blur' },
              { validator: checkPassword, trigger: 'blur' }
            ],
            yzm: [
              { required: true, message: '请输入验证码', trigger: 'blur' },
            ],
            verify: [
              { required: true, message: '请进行拼图验证', trigger: 'blur' },
            ],
            agree: [
              { required: true, type:'array', message: '请同意协议', trigger: 'blur' },
            ],

            tel: [
              { required: true, message: '请填写账号', trigger: 'blur' },
            ],
            pwd: [
              { required: true, message: '请输入密码', trigger: 'blur' },
              { validator: checkPassword, trigger: 'blur' }
            ],

            addtel: [
              { required: true, validator: checkPhone, trigger: 'blur' },
            ],

            forget_tel: [
              { required: true, validator: checkPhone, trigger: 'blur' },
              { required: true, validator: checkPhoneNotExistRule, trigger: 'blur' }
            ]
          },
          user:{
            is_authenticated: false,
            user_id: null,
            user_name: null,
            cellphone: null,
          },
          resetModalVisible: false,
          cartItemCount: '',
          reasonTime: null,
          checkLoginTime: null,
          notValidate: false
        }
    },
    created() {
        this.load_user_status()
    },
    mounted() {
      const routePath = routePathHandler(this.$route.path);
      if(this.$route.path.indexOf('/custom/') >= 0 && this.$route.params.nav_url != undefined){
        this.activeIndex = '/custom/' + this.$route.params.nav_url
      }
      else {
        this.activeIndex = routePath;
      }
      window.newThirdBindCellphone = this.newThirdBindCellphone;
      window.showLoginMessage = this.showThirdBindMessage;
      window.onresize = this.menuResponseHandler
    },
    methods: {
      menuResponseHandler() {
        const menuList = document.getElementById('menuList')
        let menuWidth = 0, loopOver = false;
        this.visibleNavData = []
        this.hideNavData = []

        new Promise(resolve => {
          for(let i=0; i<this.navData.length; i++) {
            this.visibleNavData.push(this.navData[i])
            this.$nextTick(() => {
              const containerWidth = document.getElementById('menuList').offsetWidth
              const w = menuList.getElementsByClassName('menu_item_li')[i].offsetWidth
              menuWidth += w

              if(menuWidth > containerWidth - 70 || loopOver) {
                this.visibleNavData.pop()
                this.hideNavData.push(this.navData[i])
                loopOver = true
              }
              if(i === this.navData.length - 1) {
                resolve()
              }
            })
          }
        }).then(() => {
          if(this.hideNavData.length > 0) {
            this.showMore = true
          } else {
            this.showMore = false
          }
        })
      },
      showSearchHandler() {
        this.isShowSearch = true;
        const that = this
        setTimeout(function (){
          that.$refs.searchCur.focus()
        }, 200)
        if(this.$route.name === 'CourseSearch'){
          this.searchInput = this.showSearchInput
        }
      },
      searchContentHandler(event) {
        if(event.keyCode === 13) {
          let query = replaceAll(this.searchInput, '&', '%26')
          this.$router.push('/course/search/?query=' + query)
          this.showSearchInput = ''
        }
      },
      userCommandHandler(val) {
        if(val === 'center') {
          this.goPath('/center/account');
        }
        if(val === 'repwd') {
          this.showResetPwdModal();
        }
        if(val === 'logout') {
          this.logOutOpt();
        }
      },
      async load_user_status(){
        let data = {"need_nav": true}
        if(this.$route.name == 'CustomPage' && this.$route.query.preview){
          let nav_url = this.$route.params.nav_url.join('/')
          data['now_url'] = nav_url
        }
          await GetUserState(data).then((res) => {
              if (res.id) {
                this.user.is_authenticated = true;
                this.user.user_id = res.id;
                this.user.user_name = res.username;
                this.user.cellphone = res.cellphone;
                this.addCellPhoneModal.form.user_id = res.id;
                this.loadCartItemCount();
                if (res.avatar) {
                  this.imgDataUrl = res.avatar;
                }
                this.reasonTime = setInterval(this.checkUserLogin, 10000);
              } else {
                this.checkLoginTime = setInterval(this.checkUserHasLogin, 10000);
              }
            this.navData = res.nav;

            this.menuResponseHandler()

            if (res.notValidate) {
              this.notValidate = true;
            }

            if (res.need_retain) {
              this.loginModal.loginForm.autoLogin = true;
            } else {
              this.loginModal.loginForm.autoLogin = false;
            }

            if (this.user.is_authenticated && !this.user.cellphone) {
              this.addCellPhoneModal.visible = true;
            }
          }).catch(error => {
              this.$http.get('/data/front/nav.json').then(res => {
                this.user.is_authenticated = true;
                this.user.user_id = '0000000000000';
                this.user.user_name = '纯VUE前端用户';
                this.navData = res.data.sites;
                this.menuResponseHandler()
              }).catch(error => {
                  this.$alert(`${ error }`, '提示', {
                        confirmButtonText: '确定',
                  });
              });
          });
        },

      // 登录状态中的定时任务
      checkUserLogin() {
        let that = this;
        updateUserLoginState().then((res) => {
          if (res.hasOwnProperty("error") && res.error === 'Not Login State') {
            clearInterval(that.reasonTime)
            if (!that.loginModal.visible) {
              if (res.reason) {
                let msg = `${res.reason}`
                showConfirmMessageBox(msg).then(() => {
                  that.userLogoutByOther();
                }).catch(res => {})
                setTimeout(function (){that.userLogoutByOther()},3000);
              } else {
                that.userLogoutByOther();
              }
            }
          }
        }).catch(error => {

        });
      },

      checkUserHasLogin() {
        let that = this;
        updateUserLoginState().then((res) => {
          if (res.hasOwnProperty("result") && res.result === 0) {
            clearInterval(that.checkLoginTime)
            that.load_user_status()
          }
        }).catch(error => {

        });
      },

      userLogoutByOther() {
        if (window.need_login) {
          window.location.href = "/login/?next=" + window.location.pathname + window.location.search;
        } else {
          this.$router.go(0);
        }
      },

      // 第三方登录
      startThirdPartyLogin(type) {
        updateUserLoginState().then((res) => {
          if (res.hasOwnProperty("error") && res.error === 'Not Login State') {
            ThirdPartyLogin(type)
          } else {
            this.$router.go(0);
          }
        }).catch(error => {
        });
      },
      newThirdBindCellphone(token, target_url) {
        this.addCellPhoneModal.form.token = token;
        this.addCellPhoneModal.form.target = target_url;
        this.loginModal.visible = false;
        this.addCellPhoneModal.visible = true;
      },
      showThirdBindMessage(message) {
        this.$alert(message, '提示', {
              confirmButtonText: '确定',
        });
      },
      getAddSms() {
        this.$refs.addCellPhoneForm.validateField(['addtel'], (valid) => {
          if (!valid) {
            this.addCellPhoneModal.isVerify = true;
          }
        });
      },
      getAddPhoneStatus(val) {
        // 判断图形校验是否成功
        if(val) {
          this.addCellPhoneModal.form.verify = 'success';
        } else {
          this.addCellPhoneModal.form.verify = '';
        }
      },
      getAddPhoneResult(val) {
        if(val === 'success') {
          // 图形校验成功
          this.addCellPhoneModal.isVerify = false;
          SendSms(this.addCellPhoneModal.form.addtel).then((res) => {
            if (!res.result){
              this.addCellPhoneModal.form.yzm_placeholder = `请输入短信验证码 (序列号：${res.serial})`;
              this.getAddPhoneYzm();
            } else {
              this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
              });
            }
          }).catch((error) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          });
        }
      },
      getAddPhoneYzm(surplus_time) {
        // 展示等待时间
        this.addCellPhoneModal.isGetYzm = true;
        let time = surplus_time ? surplus_time : 60;
        const countdown = setInterval(() => {
          if(time > 0) {
            time --;
            this.addCellPhoneModal.countdown = `等待 ${ time } s`
          } else {
            clearInterval(countdown);
            this.addCellPhoneModal.isGetYzm = false;
            this.addCellPhoneModal.countdown = `等待 60 s`;
          }
        }, 1000)
      },
      submitAddPhoneForm() {
        this.$refs.addCellPhoneForm.validate((valid) => {
          if (valid) {
            AddCellPhone(this.addCellPhoneModal.form)
              .then((res) => {
                if (!res.result) {
                  // this.loginModal.visible = false;
                  if (this.user.is_authenticated) {
                      this.user.cellphone = true;
                      this.addCellPhoneModal.visible = false;
                  } else {
                    this.$router.go(0);
                  }
                } else {
                  this.addCellPhoneModal.error = res.msg;
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },
      closeAddPhoneModal() {
        this.addCellPhoneModal.error = '';
        this.$refs.addCellPhoneForm.resetFields();
        if (this.user.is_authenticated && !this.user.cellphone) {
          this.logOutOpt();
        }
      },
      closeLoginModal() {

        this.loginModal.login_error = "";
        this.loginModal.register_error = "";
        this.loginModal.isVerify = false;
        this.loginModal.loginForm.pwd = '';
        console.log(this.loginModal.loginForm.pwd)
        if (this.loginModal.isLogin) {
          this.$refs.loginForm.resetFields();
        } else {
          this.$refs.registerForm.resetFields();
        }
      },
      openRegister() {
        this.loginModal.login_error = "";
        this.$refs.loginForm.resetFields();
        this.loginModal.isLogin = false;
        this.loginModal.isVerify = false
      },
      openLogin() {
        this.loginModal.register_error = "";
        this.$refs.registerForm.resetFields();
        this.loginModal.isLogin = true;
        this.loginModal.isVerify = false
      },

      // 忘记密码
      showForgetPwd() {
        this.loginModal.visible = false;
        this.forgetPwdModal.visible = true;
      },
      getForgetSms() {
        let validate = 2;
        this.$refs.forgetPwdForm.validateField(['forget_tel', 'pwd'], (valid) => {
          if (!valid) {
            validate -= 1
          }
          if (validate === 0) {
            this.forgetPwdModal.isVerify = true;
          }
        })
      },
      getForgetPwdStatus(val) {
        // 判断图形校验是否成功
        if(val) {
          this.forgetPwdModal.form.verify = 'success';
        } else {
          this.forgetPwdModal.form.verify = '';
        }
      },
      getForgetPwdResult(val) {
        if(val === 'success') {
          // 图形校验成功
          this.forgetPwdModal.isVerify = false;
          SendSms(this.forgetPwdModal.form.forget_tel).then((res) => {
            if (!res.result){
              this.forgetPwdModal.form.yzm_placeholder = `请输入短信验证码 (序列号：${res.serial})`;
              this.getForgetYzm(this.forgetPwdModal.countdowntime);
            } else {
              this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
              });
            }
          }).catch((error) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          });
        }
      },
      getForgetYzm(surplus_time) {
        // 展示等待时间
        this.forgetPwdModal.isGetYzm = true;
        let time = surplus_time ? surplus_time : 60;
        if (time < 60) { return }
        const countdown = setInterval(() => {
          if(time > 0) {
            time --;
            this.forgetPwdModal.countdown = `等待 ${ time } s`
            this.forgetPwdModal.countdowntime = `${ time }`
          } else {
            clearInterval(countdown);
            this.forgetPwdModal.isGetYzm = false;
            this.forgetPwdModal.countdown = `等待 60 s`;
            this.forgetPwdModal.countdowntime = 60
          }
        }, 1000)
      },
      submitForgetForm() {
        this.$refs.forgetPwdForm.validate((valid) => {
          if (valid) {
            ForgetPWD(this.forgetPwdModal.form)
              .then((res) => {
                if (!res.result) {
                  this.forgetPwdModal.visible = false;
                } else {
                  this.forgetPwdModal.error = res.msg;
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },
      closeForgetPwdModal() {
        this.forgetPwdModal.error = '';
        this.forgetPwdModal.visible = false;
        this.forgetPwdModal.isGetYzm = false;
        this.forgetPwdModal.isVerify = false;
        this.$refs.forgetPwdForm.resetFields();
      },

      // 注册
      getSms() {
        // 点击获取验证码，先弹出图形校验
        let validate = 2;
        this.$refs.registerForm.validateField(['register_tel', 'register_pwd'], (valid) => {
          if (!valid) {
            validate -= 1
          }
          if (validate == 0) {
            this.loginModal.isVerify = true;
          }
        });
      },
      getRegisterStatus(val) {
        // 判断图形校验是否成功
        if(val) {
          this.loginModal.registerForm.verify = 'success';
        } else {
          this.loginModal.registerForm.verify = '';
        }
      },
      getRegisterResult(val) {
        if(val === 'success') {
          // 图形校验成功
          this.loginModal.isVerify = false;
          SendSms(this.loginModal.registerForm.register_tel).then((res) => {
            if (!res.result){
              this.loginModal.registerForm.yzm_placeholder = `请输入短信验证码 (序列号：${res.serial})`;
              this.getYzm();
            } else {
              this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定',
              });
            }
          }).catch((error) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          });
        }
      },
      getYzm(surplus_time) {
        // 展示等待时间
        this.loginModal.isGetYzm = true;
        let time = surplus_time ? surplus_time : 60;
        const countdown = setInterval(() => {
          if(time > 0) {
            time --;
            this.loginModal.countdown = `等待 ${ time } s`
          } else {
            clearInterval(countdown);
            this.loginModal.isGetYzm = false;
            this.loginModal.countdown = `等待 60 s`;
          }
        }, 1000)
      },
      submitRegisterForm() {
        this.loginModal.register_error = '';
        this.$refs.registerForm.validate((valid) => {
          if (valid) {
            RegisterUser(this.loginModal.registerForm)
              .then((res) => {
                if (!res.result) {
                  // this.loginModal.visible = false;
                  this.$router.go(0);
                } else {
                  this.loginModal.register_error = res.msg
                }
              }).catch((err) => {
              this.$alert('服务异常，请稍后再试', '提示', {
                    confirmButtonText: '确定',
              });
            })
          } else {
            return false;
          }
        });
      },

      getLoginStatus(val) {
        if(val) {
          this.loginModal.loginForm.verify = 'success';
        } else {
          this.loginModal.loginForm.verify = '';
        }
      },
      getLoginResult(val) {
        if(val === 'success') {
          this.loginModal.isVerify = true;
          this.$refs.loginForm.clearValidate('verify');
        }
      },
      submitLoginForm() {
        this.loginModal.login_error = '';
        if (this.notValidate) {
          LoginUser(this.loginModal.loginForm)
            .then((res) => {
              if (!res.result) {
                this.$router.go(0);
              } else {
                this.loginModal.login_error = res.msg
              }
            }).catch((err) => {
            this.$alert('服务异常，请稍后再试', '提示', {
                  confirmButtonText: '确定',
            });
          })
        } else {
          this.$refs.loginForm.validate((valid) => {
            if (valid) {
              LoginUser(this.loginModal.loginForm)
                .then((res) => {
                  if (!res.result) {
                    this.$router.go(0);
                  } else {
                    this.loginModal.login_error = res.msg
                  }
                }).catch((err) => {
                this.$alert('服务异常，请稍后再试', '提示', {
                      confirmButtonText: '确定',
                });
              })
            } else {
              return false;
            }
          });
        }
      },

      showResetPwdModal() {
        this.resetModalVisible = true;
      },

      logOutOpt() {
        LogOutUser().then((res) => {
          if (window.need_login) {
            window.location.href = "/home";
          } else {
            window.location.reload();
          }
        }).catch((err) => {
          this.$alert('服务异常，请稍后再试', '提示', {
                confirmButtonText: '确定',
          });
        })
      },
      goPath(path) {
        this.$router.push(path);
      },

      // cart
      loadCartItemCount(){
        countCartItems({'owner_id': this.user.user_id}).then(result => {

          if (result.succeed) {
            if (result.count > 0) {
              this.cartItemCount = result.count;
            } else {
              this.cartItemCount = '';
            }

          }

        }).catch(err => {
        });
      },
      goNav(nav){
        if(nav.open_mode){
          clickNav(nav.id).then(res => {
          })
        }
      }
    },
    watch: {
      '$route' (to, from) {
          this.activeIndex = to.path;
          if(this.showMoreList) {
            this.showMoreList = false
          }
      },
      loginModal: {
        handler(val) {
          if(val.visible === false) {
            this.loginModal.isLogin = true;
          }
        },
        deep: true
      },
      loginVisible: {
        handler(val) {
          this.loginModal.visible = val;
        },
        deep: true
      },
      cartItemCount:{
        handler(val) {

          if (val > 0) {
            this.cartItemCount = val;
          } else {
            this.cartItemCount = '';
          }

        },
        deep: true
      },
      searchInput(val) {
        this.showSearchInput = this.searchInput
      }
    }
}
</script>

<style lang="scss" scoped>
    /*.el-container {*/
    /*    min-height: 100vh;*/
    /*}*/
    .el-header, .el-footer {
        background-color: #fff;
        color: #666;
        text-align: center;
        line-height: 60px;
        min-width: 1200px;
    }
    .el-header {
        position:fixed;
        top:0;
        width: 100%;
        z-index:10;
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        display:flex;
        .container_wrap {
          display:flex;
          padding: 0;
          max-width: none;
          .logo {
              width:163px;
              align-items: center;
              margin-right:20px;
              padding-top:10px;
              img {
                  height:40px;
              }
          }
          .menu {
            margin:0;
            list-style:none;
            padding-left:0;
            flex:1;
            li {
              float:left;
              .menu_item {
                display:block;
                padding-left:15px;
                padding-right:15px;
                color:#666;
                font-size:15px;
                &:hover {
                  background:#faf9f7;
                }
              }
              &.menu_more {
                position:relative;
                > a {
                  padding-left:25px;
                  padding-right:25px;
                  color:#666;
                  display:block;
                  line-height:60px;
                  &:hover {
                    background:#faf9f7;
                  }
                }
                .menu_more_list {
                  position:absolute;
                  width:160px;
                  background:#fff;
                  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.1);
                  box-shadow: 0 5px 10px rgba(0,0,0,0.1);
                  > li {
                    float:none;
                    a {
                      font-size:14px;
                      line-height:normal;
                      padding:15px;
                    }
                    &.active {
                      > a:after {
                        display:none;
                      }
                    }
                  }
                }
              }
              &.active {
                .menu_item {
                  color:#009dd9;
                  position:relative;
                  background:#faf9f7;
                  &:after {
                    content: '';
                    display:block;
                    height:4px;
                    position:absolute;
                    bottom:0;
                    left:0;
                    right:0;
                    background:#009dd9;
                  }
                }
              }
            }
          }
          :deep(.el-dropdown) {
            margin-left:auto;
            display:flex;
            align-items: center;
            .el-dropdown-link {
              color:#666;
              cursor: pointer;
              > img {
                width: 30px;
                height:30px;
                border-radius: 50%;
                vertical-align: middle;
                background:#fff;
                margin-right:10px;
              }
              > span {
                display: inline-block;
                max-width: 60px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                vertical-align: middle;
                padding-top: 5px;
                padding-bottom: 5px;
              }
            }
          }
        }
    }
    .container {
      padding-top:60px;
      .el-main {
          overflow:hidden;
          position:relative;
          min-height:500px;
      }
    }
    .el-dropdown-menu {
      a {
        font-size:12px;
        color:#666;
      }
    }
    .slide-fade-enter-active,
    .slide-fade-leave-active {
      transition: all 0.3s ease;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
      transform: translateY(10px);
      opacity: 0;
    }
    :deep(.login_modal) {
      background: linear-gradient(to bottom, #009DD9, #CBF1FF);
      &:before {
        content: '';
        background: url('~@/assets/img/bg/login.png') no-repeat;
        display:block;
        position:absolute;
        top:0;
        right:0;
        bottom:0;
        left:0;
        background-size: 100% 100%;
      }
      .el-dialog__header {
        .el-dialog__headerbtn {
          width:32px;
          height:32px;
          border-radius: 50%;
          background:rgba(255,255,255,0.1);
          text-align: center;
          line-height:32px;
          position:absolute;
          right:-37px;
          top:-37px;
          > i {
            color:#fff;
          }
        }
      }
      .content_wrap {
        background:#fff;
        border-radius:10px;
        padding:30px;
        h3 {
          font-weight: normal;
          color: #333;
          position: relative;
        }
        .el-input {
          font-size:14px;
          background:#f3f3f3;
          border:none;
          height:45px;
          line-height:45px;
          border-radius: 4px;
          .el-input__inner {
            background:#f3f3f3;
            border:none;
          }
        }
        .input_tel {
          .el-input__inner {
            padding-left:90px;
          }
          .el-input__prefix {
            font-size:14px;
            color:#666;
            padding-left:10px;
            padding-right:10px;
            &:after {
              content: '';
              top:13px;
              width:2px;
              height:18px;
              background:#e6e6e6;
              position:absolute;
              right:0;
            }
          }
        }
        .btns {
          .el-button {
            min-height: 45px;
          }
          .el-button--primary {
            box-shadow:0 4px 15px rgba(0, 173, 239, 0.2);
          }
          .el-checkbox__label, .el-button--text {
            font-size:12px;
          }
        }
        .sep {
          position:relative;
          text-align:center;
          margin-bottom:20px;
          > span {
            padding: 5px 15px;
            background:#fff;
            position: relative;
          }
          &:before {
            content: '';
            position:absolute;
            height:0;
            border-top:1px dashed #c0c4cc;
            top:8px;
            left:0;
            right:0;
          }
        }
        .third_login {
          position: relative;
          text-align: center;
          .el-button {
            margin-left:10px;
            margin-right:20px;
            font-size:40px;
          }
        }
      }
      .to_register {
        position:relative;
        width:80%;
        padding:5px;
        text-align:center;
        background:rgba(255,255,255,0.4);
        border-bottom-right-radius: 20px;
        border-bottom-left-radius: 20px;
        margin:0 auto;
        color:#999;
      }
    }
  :deep(.input_tel) {
    .el-input__inner {
      padding-left:90px;
    }
    .el-input__prefix {
      color:#666;
      padding-left:10px;
      padding-right:10px;
      &:after {
        content: '';
        top:13px;
        width:2px;
        height:18px;
        background:#e6e6e6;
        position:absolute;
        right:0;
      }
    }
  }
  .get_yzm {
    position:relative;
    .el-button, > span {
      position:absolute;
      right:10px;
      top:2px;
      font-size:12px;
    }
  }
  :deep(.forget_pwd_modal) {
    .el-input {
      line-height:45px;
      .el-input__inner {
        height:45px;
        line-height:45px;
      }
    }
  }
  /*.el-footer {*/
  /*  height:auto !important;*/
  /*}*/
  #footer {
    text-align:left;
    line-height:2;
    .content {
      background-color: #2e2e2e;
      border-bottom: 1px solid #2d2d2d;
      margin-left:-20px;
      margin-right:-20px;
      .intro {
        color:#BFBFBF;
        margin-top:0;
      }
    }
    .bottom {
      background-color: #212121;
      border-top: 1px solid #444444;
      color:#fff;
      padding:10px;
      font-size:12px;
      margin-left:-20px;
      margin-right:-20px;
      text-align:center;
      > a {
        color:#fff;
        &:hover {
          color:#00adef;
        }
      }
    }
  }
  :deep(.shopping_cart) {
    margin-right:25px;
    .el-link--inner {
      font-size:24px;
    }
    .el-badge__content.is-fixed {
      top:15px;
      line-height: normal;
      padding: 0 5px;
      right:15px;
    }
  }
  :deep(.log_and_reg) {
    font-size:15px;
  }
  :deep(.search_wrap) {
    margin-right:10px;
    display:flex;
    height:40px;
    margin-top:10px;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    width:50px;
    .el-input {
      flex: 1;
      .el-input__inner {
        border:none;
        background-color: transparent;
      }
    }
    > span {
      width:50px;
      text-align:center;
      font-size:24px;
      line-height:40px;
      cursor: pointer;
      &:hover {
        color:#009dd9;
      }
    }
    &.active {
      width:350px;
      background:#f1f1f1;
    }
  }
  .menu_hidden {
    visibility: hidden;
  }
  @media screen and (max-width: 1280px) {
    .el-header {
      .container_wrap {
        .menu {
          > li {
            .menu_item {
              padding-left:10px;
              padding-right:10px;
            }
            &.menu_more {
              > a {
                padding-left:20px;
                padding-right:20px;
              }
            }
          }
        }
      }
    }

  }
</style>
