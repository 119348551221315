<template>
  <div>
    <div class="side_btns_bar">
      <!-- 在线咨询 -->
      <div class="item zxzx">
        <el-button type="text" @click="openChat">
          <i class="iconfont icon-icon-06"></i>
          <span>在线咨询</span>
        </el-button>
      </div>
      <!-- 联系客服 -->
      <div class="item" v-if="show==true">
        <el-popover popper-class="popover"
            :offset="20"
            placement="left"
            :width="240"
            trigger="hover"
          >
            <template #reference>
              <div class="pop_btn">
                <i class="iconfont icon-icon-07"></i>
                <span>联系客服</span>
              </div>
            </template>

            <div class="row" v-if="phone">
                <i class="iconfont icon-icon-08 red"></i>
                <span>{{phone}}</span>
            </div>
            <div class="row" v-if="email">
                <i class="iconfont icon-icon-09 orange"></i>
                <el-link :underline="false" :href="`mailto:${ email }`">{{ email }}</el-link>
            </div>
            <div class="row" v-if="qq">
                <i class="iconfont icon-111_huaban1 blue"></i>
                <el-link :underline="false" target="_blank" :href="`http://wpa.qq.com/msgrd?v=3&uin=${ qq }&site=qq&menu=yes`">{{ qq }}</el-link>
            </div>
            <div class="row" v-if="wechat">
                <i class="iconfont icon-111-02 green"></i>
                <div class="wechat">
                    <span>客服微信</span>
                    <img :src="wechat" />
                </div>
            </div>
        </el-popover>
      </div>
      <!-- 公众号 -->
      <div class="item">
        <el-popover popper-class="popover"
            :offset="20"
            placement="left"
            :width="200"
            trigger="hover"
          >
            <template #reference>
              <div class="pop_btn">
                <i class="iconfont icon-icon-083"></i>
                <span>公众号</span>
              </div>
            </template>
            <img :src="require('assets/img/public_code.jpg')" class="public_code" />
            <div class="text-center" style="color:#999;">添加公众号领取免费会员</div>
        </el-popover>
      </div>
      <el-backtop :right="20" :bottom="60" :visibility-height="50" class="item">
        <i class="iconfont icon-icon-05"></i>
      </el-backtop>
    </div>
  </div>
</template>

<script>
  import { list_configures } from "api/operation/configure.js"
  export default {
    data() {
      return {
          phone: '',
          qq: '',
          email: '',
          wechat: '',
          show: false
      }
    },
    mounted() {
      let that = this
      list_configures().then((res) => {
        console.log(res.data.connection.email)
        that.email = res.data.connection.email
        that.phone = res.data.connection.phone
        that.qq = res.data.connection.qq
        that.wechat = res.data.connection.weixin
        if((that.email + that.phone + that.qq + that.wechat) != 'null'){
          that.show = true
        }
      })
    },
    methods: {
      openChat() {
        window.qimoChatClick();
      }
    }
  }
</script>

<style lang="scss" scoped>
.side_btns_bar {
    position:fixed;
    right:20px;
    bottom:140px;
    z-index:10;
    .item {
      padding:10px;
      border:1px solid #ececec;
      margin-bottom:10px;
      border-radius:2px;
      background:#fff;
      .el-button {
        text-align: center;
        padding:0;
        color:#fff;
        i {
          font-size:26px;
        }
        span {
          display:block;
          margin-top:10px;
          font-size:12px;
        }
        /*&:hover {*/
        /*  color:#009dd9;*/
        /*}*/
      }
      .el-divider {
        margin:15px 0;
      }
      .pop_btn {
        text-align: center;
        cursor: pointer;
        i {
          font-size:26px;
        }
        span {
          display:block;
          margin-top:10px;
          font-size:12px;
        }
        /*&:hover {*/
        /*  color:#009dd9;*/
        /*}*/
      }
      &.el-backtop {
        width:72px;
        height:72px;
        -webkit-box-shadow: none;
        box-shadow: none;
        i {
          color:#666;
          font-size:26px;
        }
        /*&:hover {*/
        /*  i {*/
        /*    color:#009dd9;*/
        /*  }*/
        /*}*/
      }
      &.zxzx {
        background:#ff6600;
        &:hover {
          background:#FF7418;
        }
      }
      &:hover {
        background:#f8f8f8;
      }
    }
}

.popover {
    padding:20px;
    .row {
        margin-top:10px;
        display:flex;
        > i {
            font-size:30px;
            margin-right:10px;
            &.red {
                color:#f15a51;
            }
            &.orange {
                color:#ff9900;
            }
            &.blue {
                color:#18acfc;
            }
            &.green {
                color:#00bb29;
            }
        }
        > span {
            display:flex;
            align-items: center;
        }
        .wechat {
            padding-top:10px;
            > span {
                display:block;
                margin-bottom:10px;
            }
            > img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        &:first-child {
            margin-top:0;
        }
    }
    .public_code {
      display:block;
      max-width:100%;
      margin:0 auto;
    }
}
</style>
